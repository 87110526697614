/*
This is where you write custom SASS
*/

a.ghost-btn {
    &:hover {
        border: 2px solid #ff7d3b !important;
        background: $primary !important;
        color: $white !important;
        svg {
            color: $white !important;
        }
    }
}

section.cta-section {
    @media screen and (max-width: 1199px) {
        [style*="height: 450px"] {
            height: 380px !important;
        }
    }
    @media screen and (max-width: 991px) {
        div.panel-image[style*="cta1"] {
            background-position: 50% 20% !important;
        }
    }
    .cta-wrap {
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(rgba(33, 21, 14, 0) 0%, #29221e 100%);
            opacity: 0.86;
            z-index: 1;

        }
        &:hover {
            > .panel-image {
                transform: scale(1.05);
            }
        }
        > .panel-image {
            transition: .3s ease;
        }
    }
}

nav {
    .socials {
        a {
            border: 1px solid $primary;
        }
    }
    @media screen and (min-width: 992px) {
        ul#main-menu {
            ul {
                li {
                    a {
                        border-bottom: 1px solid #E6E6E6;
                        &:hover {
                            background: #FF853E !important;
                            border-bottom: 1px solid #FF7D3B !important;
                            color: #fff !important;
                        }
                        &.current, &.highlighted {
                            background: #FF853E !important;
                            border-bottom: 1px solid #FF7D3B !important;
                            color: #fff !important;
                        }
                    }
                }
            }
        }
    }
}

section.home-carousel {
    .cta-bar {
        transform: translateX(-50%);
        left: 50%;
        bottom: -60px;
        z-index: 2;
        @media screen and (max-width: 991px) {
            .btn {
                max-width: 300px !important;
            }
        }
    }

    .tagline-container {
        left: 50%;
        top: 50%;
        transform: translate3d(-50%,-50%,0);
        z-index: 1;
    }
    @media screen and (max-width: 991px) {
        [style*="height: 815px"] {
            height: 600px !important;
        }
    }
    @media screen and (max-width: 567px) {
        [style*="height: 815px"] {
            height: 400px !important;
        }
    }
}

section.page-content {
    @media screen and (max-width: 991px) {
        .panel-image {
            height: 53vw;
        }
        [style*="max-width: 750px"] {
            max-width: initial !important;
        }
        [style*="welcome-img.jpg"] {
            background-position: 39% 45% !important;
        }
    }
}

section.testimonials {
    @media screen and (max-width: 576px) {
        .testimonials-btns .btn {
            display: block;
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        .ghost-btn {
            margin-top: 10px !important;
            max-width: 246px !important;
        }
    }

    .owl-nav .owl-prev,
    .owl-nav .owl-next {
        font-size: 35px !important;
        color: rgba(255,125,59,.8) !important;
    }

    .owl-nav .owl-prev:hover,
    .owl-nav .owl-next:hover {
        color: rgba(107,103,102,.31) !important;
    }

    .owl-nav {
        left: 0;
        right: auto !important;
        top: auto !important;
        bottom: 20px;
        margin: 0 !important;
    }

    .owl-nav .owl-prev {
        left: -20px;
    }

    .owl-nav .owl-next {
        left: 25px;
    }


    @media screen and (max-width: 991px) {
        .panel-image {
            height: 53vw;
        }
        [style*="max-width: 750px"] {
            max-width: initial !important;
        }
        [style*="testimonials-img.jpg"] {
            background-position: 50% 50% !important;
        }
    }
    cite {
        span {
            opacity: .5;
        }
    }
    .owl-carousel:before {
        content: '';
        width: 42px;
        height: 28px;
        background-image: url('/prositesstyles/stylelibrary/6387/images/quote.png');
        z-index: 5;
        position: absolute;
        top: 0;
        right: 100%;
        margin-right: 20px;
        @media screen and (max-width: 1270px) {
            & {
                content: none;
            }
        }
    }

}

section.map-section {
    @media screen and (max-width: 991px) {
        iframe {
            height: 200px !important;
        }   
    }
}

section.association-section {
    @media screen and (max-width: 1300px) {
        img {
            max-width: 10vw !important;
        }   
    }
    @media screen and (max-width: 767px) {
        img {
            max-width: 14vw !important;
        }   
    }
}

section.directions-section {
    .separator:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1px;
        height: 36px;
        background: rgba(255,255,255,0.5);
        transform: translate3d(-50%,-40%,0);
    }
    
    @media screen and (max-width: 1199px) {
        .separator {
            display: none;
        }
    }
}

footer {
    .footer-socials {
        a {
            border: 1px solid $primary;
        }
    }
    p {
        color: rgba(235,235,235,.31);
        a {
            
            color: rgba(235,235,235,.31) !important;
            &:hover {
                color: darken(rgba(235,235,235,.31),10%) !important;
            }
        }
    }
}


body.interior {
    section.map-section {
        input,textarea {
            border: 1px solid transparent !important;
        }
        .form-group {
            margin-top: 0px !important;
            margin-bottom: 10px !important;
        }
    }
}