.opacity-100 {
opacity:100 !important;
}

.py-15px {
padding-top:15px !important;padding-bottom:15px !important;
}

@media screen and (min-width: 992px) {.pt-lg-0 {
padding-top:0px !important;
}

}
 .pt-20px {
padding-top:20px !important;
}

.mb-15px {
margin-bottom:15px !important;
}

.font-size-22px {
font-size:22px !important;
}

.font-size-18px {
font-size:18px !important;
}

.mr-10px {
margin-right:10px !important;
}

.font-size-22px {
font-size:22px !important;
}

.mr-10px {
margin-right:10px !important;
}

.font-size-17px {
font-size:17px !important;
}

.p-0 {
padding:0px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.w-100 {
width:100% !important;
}

.font-size-14 {
font-size:14px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.h-100 {
height:100% !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.p-20px {
padding:20px !important;
}

.w-100 {
width:100% !important;
}

.bg-900 {
color:900px !important;
}

.p-25px {
padding:25px !important;
}

.bg-900 {
color:900px !important;
}

.font-size-17px {
font-size:17px !important;
}

.ml-10px {
margin-left:10px !important;
}

.mt-10px {
margin-top:10px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

@media screen and (min-width: 768px) {.mb-md-80px {
margin-bottom:80px !important;
}

}
 .px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.mb-15px {
margin-bottom:15px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

.font-weight-600 {
font-weight:600 !important;
}

.font-size-15px {
font-size:15px !important;
}

.py-25px {
padding-top:25px !important;padding-bottom:25px !important;
}

.px-5px {
padding-left:5px !important;padding-right:5px !important;
}

.w-100 {
width:100% !important;
}

.mr-5px {
margin-right:5px !important;
}

.font-weight-600 {
font-weight:600 !important;
}

.font-size-15px {
font-size:15px !important;
}

.py-25px {
padding-top:25px !important;padding-bottom:25px !important;
}

.px-5px {
padding-left:5px !important;padding-right:5px !important;
}

.w-100 {
width:100% !important;
}

.ml-5px {
margin-left:5px !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

.font-weight-600 {
font-weight:600 !important;
}

.font-size-15px {
font-size:15px !important;
}

.py-25px {
padding-top:25px !important;padding-bottom:25px !important;
}

.px-5px {
padding-left:5px !important;padding-right:5px !important;
}

.w-100 {
width:100% !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.font-weight-600 {
font-weight:600 !important;
}

.font-size-15px {
font-size:15px !important;
}

.py-25px {
padding-top:25px !important;padding-bottom:25px !important;
}

.px-5px {
padding-left:5px !important;padding-right:5px !important;
}

.w-100 {
width:100% !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.w-100 {
width:100% !important;
}

@media screen and (min-width: 992px) {.mb-lg-85px {
margin-bottom:85px !important;
}

}
 .font-weight-400 {
font-weight:400 !important;
}

.px-5px {
padding-left:5px !important;padding-right:5px !important;
}

.w-100 {
width:100% !important;
}

.py-14px {
padding-top:14px !important;padding-bottom:14px !important;
}

.mt-40px {
margin-top:40px !important;
}

@media screen and (min-width: 992px) {.mt-lg-130px {
margin-top:130px !important;
}

}
 .mb-40px {
margin-bottom:40px !important;
}

.h-100 {
height:100% !important;
}

.h-100 {
height:100% !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.h-100 {
height:100% !important;
}

.pt-75px {
padding-top:75px !important;
}

@media screen and (min-width: 992px) {.pb-lg-75px {
padding-bottom:75px !important;
}

}
 .font-size-17px {
font-size:17px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.line-height-28px {
line-height:28px !important;
}

.py-40px {
padding-top:40px !important;padding-bottom:40px !important;
}

@media screen and (min-width: 992px) {.py-lg-75px {
padding-top:75px !important;padding-bottom:75px !important;
}

}
 .mb-40px {
margin-bottom:40px !important;
}

@media screen and (min-width: 992px) {.mb-lg-850px {
margin-bottom:850px !important;
}

}
 .h-100 {
height:100% !important;
}

@media screen and (min-width: 992px) {.pt-lg-0 {
padding-top:0px !important;
}

}
 .pt-40px {
padding-top:40px !important;
}

.pb-25px {
padding-bottom:25px !important;
}

.font-size-19px {
font-size:19px !important;
}

.line-height-29px {
line-height:29px !important;
}

.opacity-50 {
opacity:50 !important;
}

.font-size-18px {
font-size:18px !important;
}

.mt-40px {
margin-top:40px !important;
}

.w-100 {
width:100% !important;
}

.px-5px {
padding-left:5px !important;padding-right:5px !important;
}

.mr-5px {
margin-right:5px !important;
}

.ml-5px {
margin-left:5px !important;
}

.w-100 {
width:100% !important;
}

.px-5px {
padding-left:5px !important;padding-right:5px !important;
}

.mt-40px {
margin-top:40px !important;
}

.w-100 {
width:100% !important;
}

.mr-5px {
margin-right:5px !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

@media screen and (min-width: 992px) {.py-lg-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

}
 @media screen and (min-width: 992px) {.pb-lg-0 {
padding-bottom:0px !important;
}

}
 .pb-40px {
padding-bottom:40px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.w-100 {
width:100% !important;
}

.font-weight-600 {
font-weight:600 !important;
}

.font-size-17px {
font-size:17px !important;
}

.mb-5px {
margin-bottom:5px !important;
}

.font-size-17px {
font-size:17px !important;
}

.mb-5px {
margin-bottom:5px !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

@media screen and (min-width: 992px) {.py-lg-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

}
 .mx-25px {
margin-left:25px !important;margin-right:25px !important;
}

.pt-20px {
padding-top:20px !important;
}

.py-40px {
padding-top:40px !important;padding-bottom:40px !important;
}

.font-size-24px {
font-size:24px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

@media screen and (min-width: 1200px) {.pb-xl-0 {
padding-bottom:0px !important;
}

}
 .pb-5px {
padding-bottom:5px !important;
}

.mx-20px {
margin-left:20px !important;margin-right:20px !important;
}

.font-size-18px {
font-size:18px !important;
}

.mr-10px {
margin-right:10px !important;
}

.font-size-22px {
font-size:22px !important;
}

.mr-10px {
margin-right:10px !important;
}

.font-size-17px {
font-size:17px !important;
}

.bg-900 {
color:900px !important;
}

.py-30px {
padding-top:30px !important;padding-bottom:30px !important;
}

@media screen and (min-width: 992px) {.py-lg-60px {
padding-top:60px !important;padding-bottom:60px !important;
}

}
 .py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.m-0 {
margin:0px !important;
}

.py-30px {
padding-top:30px !important;padding-bottom:30px !important;
}

.font-size-15px {
font-size:15px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.ml-4 {
margin-left:4px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.p-0 {
padding:0px !important;
}

